/* eslint-disable max-len */
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Text from './../Components/Text'
import Grid from './../Components/VGrid'
import { colors } from '../Themes'
import { TermsCollapse } from '../Components/Collapses'
import { COOKIES, PRIVACYPOLICY } from '../Utils/Cookies'

const useStyles = makeStyles((theme) => ({
  // mainscreen
  mainWrapper: {
    display: 'flex',
    background: colors.white,
    width: '100%',
    marginTop: 30,
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingBlock: 40,
    marginInline: 'auto',
    maxWidth: 1100,
    flexDirection: 'column',
    [theme.breakpoints.up(1921)]: {
      maxWidth: 1300,
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down(1440)]: {
      maxWidth: 1000,
      justifyContent: 'space-between',
      gap: 0,
    },
    [theme.breakpoints.down(1100)]: {
      maxWidth: 900,
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down(960)]: {
      maxWidth: 700,
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down(760)]: {
      maxWidth: '100%',
      flexDirection: 'column',
      gap: 42,
      paddingInline: 16,
      paddingBlock: 40,
    },
  },
  mainSection: {
    paddingLeft: '20%',
    paddingRight: '20%',
    paddingTop: 50,
    paddingBottom: 50,
    transition: '0.5s',
    [theme.breakpoints.down('sm')]: {
      transition: '0.5s',
      // marginTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
    },
    [theme.breakpoints.only('md')]: {
      paddingLeft: 70,
      paddingRight: 70,
    },
    [theme.breakpoints.up(2400)]: {
      maxWidth: '100%',
      padding: '100px 500px !important',
    },
    [theme.breakpoints.up(1920)]: {
      maxWidth: '100%',
      padding: '100px 300px',
    },
  },
  effective: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    marginTop: 24
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center'
  },
  space: {
    paddingTop: 30,
    paddingLeft: 0,
    [theme.breakpoints.down('md')]: {
      paddingTop: 30,
    },
  },
}))

function PrivacyPolicy() {
  const [expandedItem, setExpandedItem] = useState(0)

  const expand = (key) => {
    setExpandedItem((prev) => {
      if (prev === key) {
        return -1
      }
      return key
    })
  }

  const classes = useStyles()

  return (
    <section className={classes.mainWrapper}>
      <div className={classes.title}>
        <Text size='h2' weight='medium'>Privacy Policy</Text>
      </div>
      <div className={classes.effective}>
        <Text size="body" weight="regular" color={colors.primary}>
        Last Updated: 21 August 2024
        </Text>
        <Text size="body" weight="regular" color={colors.darkGrey}>
        Tegeria Ltd (&quot;we&quot;, &quot;our&quot;, &quot;us&quot;) operates the product <b> dataplayer.io</b> (hereinafter referred to as the &quot;Service&quot;). This Privacy Policy governs your visit to dataplayer.io and explains how we collect, safeguard, and disclose information resulting from your use of our Service. </Text>
        <Text size="body" weight="regular" color={colors.darkGrey}>
        By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy. Our Terms and Conditions (&quot;Terms&quot;) govern all use of our Service and, together with this Privacy Policy, constitute your agreement with us (&quot;Agreement&quot;).
        </Text>
      </div>
      <div style={{ marginTop: 30 }}>
        {PRIVACYPOLICY?.map((item, index) => {
          return (
            <TermsCollapse
              key={index}
              title={item.title}
              description={item.desc}
            />
          )
        })}
      </div>
      <div className={classes.effective} style={{ marginTop: 48 }}>
        <Text size='h4' weight='medium' color={colors.primary}>Contact Information</Text>
        <Text size='body' color='#555555'>For questions about this Privacy Policy, please contact us at:</Text>
        <Text size='body' color='#555555'><b>Email:</b> info@dataplayer.io</Text>
        <Text size='body' color='#555555'><b>Adress:</b>Tegeria Ltd, Blv. Zogu I, Qendra e Biznesit Tirana, Kati 11, 1001, Albania</Text>
      </div>
    </section>
  )
}

export default PrivacyPolicy
